import React from "react";
import Background from "../primitives/background";
import Typography from "../primitives/typography";
import FadeTransitionFramer from "../animations/transitions/fade-transition-framer";
import AspectRatio from "../primitives/aspect-ratio";
import Swatch from "./swatch";
import StyledButton from "../primitives/styled-button";
import AnimatedCheckbox from "../primitives/animated-checkbox";
import VisuallyHidden from "../primitives/visually-hidden";
import {Link} from "../../../plugins/gatsby-plugin-atollon";
import {useCompareContext} from "../../contexts/compare-context";
import {ProductRenderDisplayVariant} from "../../importer/customizer/utils/get-variants-for-display";
import {useStore} from "../../contexts/store-context";
import {useAllProductsContext} from "../../contexts/all-products-context";
import {useToastContext} from "../../contexts/toast-context";
import useShopifyIds from "../../hooks/use-shopify-ids";
import {breakpoints} from "../primitives/tokens";


const ProductCard = ({ product, applyFilters, isInlaid, isH2 }: { product: ProductRenderDisplayVariant, applyFilters?: boolean, isInlaid?: boolean, isH2?: boolean }) => {

    const [colorIndex, setColorIndex] = React.useState(0)
    const {
        items,
        setItems
    } = useCompareContext()

    const {
        filteredVariants,
        unfilteredVariants
    } = useAllProductsContext();

    const variants = applyFilters ? filteredVariants : unfilteredVariants

    if (!variants[product.handle]) {
        return null;
    }

    const allVariants = [variants[product.handle]].concat(product.related.map(x => variants[x]))
    const currentVariant = allVariants[colorIndex]

    const currentVariantIsInCompare = !!items.find(x => x.handle === currentVariant.handle)

    const {
        cart,
        freeSamplesAllowedCount,
    } = useStore()

    const {
        createFreeSampleToast,
        createCompareToast
    } = useToastContext()

    const productIds = useShopifyIds();

    const freeSampleIsInCart = cart.lines?.find(x => x?.merchandise?.id ===  productIds[currentVariant.freeSampleHandle])

    const Component = isH2 ? "h2" : "h3"

    return (
        <Background
            backgroundColor="secondaryBeige2"
            css={{
                position: 'relative',
                userSelect: 'none',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Link css={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '0',
                top: '0',
                zIndex: '1',
            }} to={currentVariant.permalink}>
                <VisuallyHidden>{currentVariant.fabricTitle} {currentVariant.productTitle} - {currentVariant.colorTitle}</VisuallyHidden>
            </Link>
            <div css={{
                position: 'relative'
            }}>
                <AspectRatio breakpoints={{
                    dt: { width: 443, height: 440 }
                }}>
                    <img css={{
                        height: '100%',
                        objectFit: 'cover',
                    }} src={`${currentVariant.renderImages[0]}?w=600`} />
                </AspectRatio>
                <StyledButton css={{
                    position: 'absolute',
                    right: '20px',
                    top: '20px',
                    padding: 'unset',
                    height: '29px',
                    width: '115px',
                    [breakpoints.mb]: {
                        right: '13px',
                        top: '13px'
                    }
                }} disabled={!freeSampleIsInCart && freeSamplesAllowedCount < 1} className="fill-white" onClick={() => {
                    if (freeSampleIsInCart) {
                        cart.linesRemove([freeSampleIsInCart.id])
                    } else {
                        createFreeSampleToast(currentVariant)
                        console.log(productIds, currentVariant)
                        cart.linesAdd([{
                            merchandiseId: productIds[currentVariant.freeSampleHandle],
                            quantity: 1,
                        }])
                    }
                }}>
                    <FadeTransitionFramer shouldChange={(!!freeSampleIsInCart).toString()}>
                        <Typography fontSize={{
                            dt: "c1"
                        }} css={{ display: 'flex', gap: '6px', alignItems: 'center', justifyContent: 'center' }}>
                            {!freeSampleIsInCart && (
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.99958 19.2853C15.1279 19.2853 19.2853 15.1279 19.2853 9.99958C19.2853 4.87122 15.1279 0.713867 9.99958 0.713867C4.87122 0.713867 0.713867 4.87122 0.713867 9.99958C0.713867 15.1279 4.87122 19.2853 9.99958 19.2853Z" stroke="currentColor" strokeWidth="0.714286" strokeMiterlimit="10"/>
                                    <path d="M10.0059 4.10059V15.4947" stroke="currentColor" strokeWidth="0.714286" strokeLinejoin="bevel"/>
                                    <path d="M16.1907 9.98145H3.80957" stroke="currentColor" strokeWidth="0.714286" strokeLinejoin="bevel"/>
                                </svg>
                            )}
                            {freeSampleIsInCart && (
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2934_17743)">
                                        <path d="M0.714844 10.4996C0.714844 5.37128 4.87232 1.21387 10.0007 1.21387C15.1291 1.21387 19.2863 5.37128 19.2863 10.4996C19.2863 15.6279 15.1291 19.7853 10.0007 19.7853C4.87231 19.7853 0.714844 15.6279 0.714844 10.4996Z" stroke="currentColor" strokMiterlimit="10"/>
                                        <path d="M5 11.1364L8.33333 14.7727L15 7.5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2934_17743">
                                            <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}
                            {!freeSampleIsInCart && (
                                <span>Free Sample</span>
                            )}
                            {freeSampleIsInCart && (
                                <span>Sample In Cart</span>
                            )}
                        </Typography>
                    </FadeTransitionFramer>
                </StyledButton>
            </div>
            <div css={{
                padding: '28px 24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                [breakpoints.mb]: {
                    padding: '20px'
                }
            }}>
                <Component>
                    <Typography fontSize={{
                        dt: "h4"
                    }} fontWeight="medium" color="neutral9">
                        {currentVariant.fabricTitle}
                    </Typography>
                    <Typography fontSize={{
                        dt: "h4"
                    }} fontWeight="regular" color="neutral9">
                        {currentVariant.productTitle}
                    </Typography>
                    <FadeTransitionFramer shouldChange={currentVariant.colorTitle}>
                        <Typography fontSize={{
                            dt: "p1"
                        }} color="neutral9" css={{
                            marginTop: "4px"
                        }}>
                            {currentVariant.colorTitle}
                        </Typography>
                    </FadeTransitionFramer>
                </Component>
                <div css={{
                    marginTop: '28px',
                    [breakpoints.mb]: {
                        marginTop: '24px'
                    }
                }}>
                    <div>
                        <Swatch
                            breakpoints={isInlaid ? {
                                dt: { itemsAtSize: 8 },
                                tb: { itemsAtSize: 8 },
                                mb: { itemsAtSize: 8 }
                            } : {
                                dt: { itemsAtSize: 8 },
                                tb: { itemsAtSize: 12 },
                                mb: { itemsAtSize: 14 }
                            }}
                            variants={allVariants}
                            colorIndex={colorIndex}
                            setColorIndex={setColorIndex}
                            link={currentVariant.permalink}
                        />
                    </div>
                    <div css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '28px'
                    }}>
                        <div>
                            <Typography
                                fontSize={{
                                    dt: 'p2',
                                }}
                                as="span"
                                color="shadesBlack"
                            >
                                From
                            </Typography>
                            <Typography
                                fontSize={{
                                    dt: 'h4',
                                }}
                                as="span"
                                color="neutral10"
                                css={{
                                    paddingLeft: '4px',
                                    [breakpoints.mb]: {
                                        paddingLeft: '3px'
                                    }
                                }}
                            >
                                {currentVariant.price}
                            </Typography>
                        </div>
                        <div css={{
                            padding: '10px',
                            marginRight: '-10px',
                            marginBottom: '-5px',
                            position: 'relative',
                            cursor: 'pointer',
                            zIndex: 1,
                        }} onClick={() => {
                            if (currentVariantIsInCompare) {
                                setItems((items) => {
                                    return items.filter(x => x.handle !== currentVariant.handle)
                                })
                            } else {
                                createCompareToast(currentVariant)
                                setItems((items) => {
                                    return [...items, currentVariant]
                                })
                            }
                        }}>
                            <AnimatedCheckbox
                                checked={currentVariantIsInCompare}
                                size="sm"
                                setChecked={() => {
                                    if (currentVariantIsInCompare) {
                                        setItems((items) => {
                                            return items.filter(x => x.handle !== currentVariant.handle)
                                        })
                                    } else {
                                        createCompareToast(currentVariant)
                                        setItems((items) => {
                                            return [...items, currentVariant]
                                        })
                                    }
                                }}
                            >
                                Compare
                            </AnimatedCheckbox>
                        </div>
                    </div>
                </div>
            </div>
        </Background>
    )
}

export default ProductCard;
